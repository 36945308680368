import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import Auth from "@/layouts/Auth.vue";
import Login from "@/views/auth/Login.vue";
import { getCookie } from '@/utils/cookie';
import PendingLogin from '@/layouts/PendingLogin.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';

const twoFactorAuthRequired = (() => {
    const storedValue = localStorage.getItem("twoFactorAuthRequired");
    if (storedValue === null) {
        return null;
    }
    try {
        return JSON.parse(storedValue);
    } catch (e) {
        return null;
    }
})();

const twoFactorSetupDone = (() => {
    const storedValue = localStorage.getItem("twoFactorSetupDone");
    if (storedValue === null) {
        return null;
    }
    try {
        return JSON.parse(storedValue);
    } catch (e) {
        return null;
    }
})();

const passwordChangeRequired = (() => {
    const storedValue = localStorage.getItem("passwordChangeRequired");
    if (storedValue === null) {
        return null;
    }
    try {
        return JSON.parse(storedValue);
    } catch (e) {
        return null;
    }
})();

const routes = [
    {
        path: '/',
        name: 'Admin',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: Dashboard,
                meta: { title: 'TennisInc - Dashboard' }
            },
            {
                path: '/admin/useraccounts',
                name: 'User Accounts Management',
                component: () => import('@/views/admin/useraccounts/UserAccounts.vue'),
                meta: { title: 'TennisInc - User Accounts Management' }
            },

        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/user/profile/',
        name: 'User Profile',
        component: DefaultLayout,
        children: [
            {
                path: '',
                name: 'Edit User Profile',
                component: () => import('@/views/admin/user/UserProfile.vue'),
                meta: { title: 'TennisInc - User Profile' }
            },
            {
                path: '/user/2fa/setup',
                name: 'Setup Two Factor Authentication',
                component: () => import('@/views/admin/user/TwoFASetup.vue'),
                meta: { title: 'TennisInc - 2FA Setup' }
            },
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/inventory/items/',
        name: 'Inventory',
        component: DefaultLayout,
        children: [
            {
                path: '/inventory/items/',
                name: 'Inventory Items Masterlist',
                component: () => import('@/views/admin/inventory/Items.vue'),
                meta: { title: 'TennisInc - Inventory Items' }
            },
            {
                path: '/inventory/pull_tasks/',
                name: 'Pull Tasks',
                component: () => import('@/views/admin/inventory/PullJobs.vue'),
                meta: { title: 'TennisInc - Pull Jobs' }
            },
            {
                path: '/inventory/push_tasks/',
                name: 'Push Tasks',
                component: () => import('@/views/admin/inventory/PushJobs.vue'),
                meta: { title: 'TennisInc - Push Jobs' }
            },
            {
                path: '/inventory/item_history/',
                name: 'Inventory Item History',
                component: () => import('@/views/admin/inventory/ItemHistory.vue'),
                meta: { title: 'TennisInc - Item History' }
            },
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings/site/',
        name: 'Settings',
        component: DefaultLayout,
        children: [
            {
                path: '/settings/site/',
                name: 'Site settings',
                component: () => import('@/views/admin/settings/SiteSettings.vue'),
                meta: { title: 'TennisInc - Site Settings' }
            },
            {
                path: '/settings/backup/',
                name: 'Backup Settings',
                component: () => import('@/views/admin/settings/Backup.vue'),
                meta: { title: 'TennisInc - Site Backup' }
            },
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: "login",
        path: "/auth",
        redirect: "/auth/login",
        component: Auth,
        children: [
            {
                path: "/auth/login",
                component: Login,
                meta: { title: 'TennisInc - Login' }
            },
            {
                path: "/auth/forgotpassword",
                component: () => import('@/views/auth/ForgotPassword.vue'),
                meta: { title: 'TennisInc - Forgot Password' }
            }
        ],
    },
    {
        path: "/pendinglogin",
        name: "Pending Login",
        component: PendingLogin,
        children: [
            {
                name: "Password Change Required",
                path: "/pendinglogin/forcepasswordchange",
                component: () => import('@/components/user/ForcePasswordChange.vue'),
                meta: { title: 'TennisInc - Mandatory Password Change' }
            },
            {
                name: "2FA Verification",
                path: "/pendinglogin/verify2fa",
                component: () => import('@/components/user/Verify2FA.vue'),
                meta: { title: 'TennisInc - Verify 2FA' }
            },
        ],
        meta: {
            requiresAuth: true,
        },
    },
]

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'TennisInc'; // Default title
    }
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    // check if not undefined 
    const isLoggedin = JSON.parse(localStorage.getItem("userLoggedIn") || "false");
    const is2faVerified = getCookie('_2facookie');

    if (to.name === '404' || to.name === 'sessionExpired') {
        next();
    } else {
        if (isLoggedin) {
            if (passwordChangeRequired) {

                if (to.path !== "/pendinglogin/forcepasswordchange") {
                    return next("/pendinglogin/forcepasswordchange");
                } else {
                    return next();
                }
            } else if (twoFactorAuthRequired && !twoFactorSetupDone) {
                if (to.path !== "/useraccount/2fasetup") {
                    return next("/useraccount/2fasetup");
                } else {
                    return next();
                }
            } else if (twoFactorAuthRequired && twoFactorSetupDone && !is2faVerified) {
                if (to.path !== "/pendinglogin/verify2fa") {
                    return next("/pendinglogin/verify2fa");
                } else {
                    return next();
                }
            } else if (!twoFactorAuthRequired && twoFactorSetupDone && !is2faVerified) {
                if (to.path !== "/pendinglogin/verify2fa") {
                    return next("/pendinglogin/verify2fa");
                } else {
                    return next();
                }
            } else {
                return next();
            }
        } else {
            if (!requiresAuth) {
                return next();
            } else {
                return next("/auth/login/");
            }
        }
    }
});

export default router
