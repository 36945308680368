import { useRouter } from "vue-router";

export const getMomentTimestamp = (moment: any, timestamp: string) => {
  return moment(timestamp, "YYYY-MM-DD HH:mm:SSZ").format(
    "MMMM DD YYYY h:mm:SS A"
  );
};

export const toTitleCase = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generateRandomHash = (length: number = 10) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function navigateTo(routeName : string) {
    const router = useRouter();
    router.push({ name: routeName });
}

export function isNumeric(value : any) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export function formatNumber(number : any, force_decimals : any) {
    let formattedNumber = "";
    if (!isNumeric(number) && number != null) {
        number = number.replace(/,/g, '').split(' ')[1]; // remove comma, if the passed value has a currency sign, split on space and get the 2nd
        try {
            number = parseFloat(number);
        } catch (error) {
            number = null;
        }
    }

    if (number) {
        if (isNumeric(number)) {
            // Check if the number is greater than or equal to 1,000
            if (Math.abs(number) >= 1000) {
                formattedNumber = parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                // Add comma separator and show two decimal places
            } else {
                formattedNumber = parseFloat(number).toFixed(2);
                // Show two decimal places without comma separator
            }

            // Check if the number is a whole number
            if (!force_decimals || force_decimals == 0 || force_decimals == '0') {
                if (formattedNumber.endsWith('.00')) {
                    formattedNumber = formattedNumber.slice(0, -3);
                    // Discard the decimal point and decimal digits
                }
            }

            return formattedNumber;
        }
    } else {
        return '0';
    }
}

export const arrayToOptions = (arr: any[], addAnyOption : boolean, labelIsValue : boolean): { label: string; value: number }[] => {
    let options = arr.map(item => ({
        label: item,
        value: item
    }));

    if (!labelIsValue) {
        options = arr.map(item => ({
            label: item.name,
            value: item.id
        }));
    }
    
    if (addAnyOption) {
        // Prepend the 'Any' option
        options.unshift({ label: 'Any', value: 0 });
    } else {
        options.unshift({ label: '---------', value: 0 });
    }
    
    return options;
};

export const resolveImage = (path : any) => {
    if (String(path).includes('http')) {
        return path;
    } else {
        return `${localStorage.getItem('host')}${path}`;
    }
}

export const renderSiteNameAsInitials = (siteName : string) => {
    if (siteName.length > 10) {
        if (siteName.includes(' ')) {
            // get the first letter of each word to make the initials
            const words = siteName.split(' ');
            // Extract the first letter of each word and join them
            let initials = words.map(word => word[0]).join('');
            if (initials.length <= 2) {
                initials = `${initials}S`;
            }
            return initials;   
        }
    } else {
        return `${siteName}`;
    }
    
}

export const currencyFormat = (amount: number) => {
    return {
      displayAmount: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount),
    };
};

export const renderQtyWithUom = (qty : number, uom : string) => {
    return `${formatNumber(qty, false)} ${uom}`;
}

export const focusAndSelectFirstInput = () => {
    setTimeout(() => {
        const formElement = document.querySelector('form') as HTMLFormElement | null;
        if (formElement) {
            let input = formElement.querySelector('.controls input[type="text"]') as HTMLInputElement | null;
            if (input) {
                input.focus();
                input.select();
            } else {
                input = formElement.querySelector('.controls input[type="number"]') as HTMLInputElement | null;
                if (input) {
                    input.focus();
                    input.select();
                }
            }
        }
    }, 400)
};

export const parseToBoolean = (value : string) => {
    return value === '0' ? false : Boolean(value);
}