export default [
    {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
            color: 'primary',
            text: 'NEW',
        },
    },
    {
        component: 'CNavItem',
        name: 'User Accounts',
        to: '/admin/useraccounts',
        icon: 'fas fa-users',

    },
    {
        component: 'CNavGroup',
        name: 'Inventory',
        to: '/inventory',
        icon: 'fas fa-cubes',
        items: [
            {
                component: 'CNavItem',
                name: 'Pull Tasks',
                to: '/inventory/pull_tasks/',
                icon: 'fas fa-cloud-download-alt',
            },
            {
                component: 'CNavItem',
                name: 'Push Tasks',
                to: '/inventory/push_tasks/',
                icon: 'fas fa-cloud-upload-alt',
            },
            {
                component: 'CNavItem',
                name: 'Items Masterlist',
                to: '/inventory/items/',
                icon: 'cil-list',
            },

        ]
    },
    {
        component: 'CNavGroup',
        name: 'Settings',
        to: '/settings/site/',
        icon: 'fas fa-cogs',
        items: [
            {
                component: 'CNavItem',
                name: 'Site Settings',
                to: '/settings/site/',
                icon: 'fas fa-list',
            },
            {
                component: 'CNavItem',
                name: 'Data Backup',
                to: '/settings/backup/',
                icon: 'fas fa-database',
            },
        ]
    },

]  