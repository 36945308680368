<template>
    <div class="hold-transition login-page">
      <div class="login-box">
        <!-- /.login-logo -->
        <RouterView />
        <!-- /.card -->
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  </script>  