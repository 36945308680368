<script setup>
import { onBeforeMount, onMounted, computed, watch } from 'vue'
import { useColorModes } from '@coreui/vue'

import { useThemeStore } from '@/stores/theme.js'

import useGeneralStore from "@/stores/general";
const generalStore = useGeneralStore();
const faviconSrc = computed(() => `${generalStore.host}${generalStore.siteFavicon}`);
const pageTitle = 'Login';
const docTitle = computed(() => `${generalStore.siteName} - ${pageTitle}`);
const { isColorModeSet, setColorMode } = useColorModes(
    'coreui-free-vue-admin-template-theme',
)
const currentTheme = useThemeStore()

onBeforeMount(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    let theme = urlParams.get('theme');

    if (theme !== null && theme.match(/^[A-Za-z0-9\s]+/)) {
        theme = theme.match(/^[A-Za-z0-9\s]+/)[0]
    }

    if (theme) {
        setColorMode(theme)
        return
    }

    if (isColorModeSet()) {
        return
    }

    setColorMode(currentTheme.theme)
})

onMounted(async () => {
    await generalStore.getSiteInfo();

});

watch(faviconSrc, (newFaviconSrc) => {
    // Create a new link element for the favicon
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = newFaviconSrc;

    // Remove any existing favicon links
    const existingFavicon = document.querySelector('link[rel="icon"]');
    if (existingFavicon) {
        document.head.removeChild(existingFavicon);
    }

    // Append the new favicon link to the document head
    document.head.appendChild(link);
}, { immediate: true });


watch(docTitle, (newDocTitle) => {
  if (newDocTitle) {
    document.title = newDocTitle;
  }
}, { immediate: true });
</script>

<template>
    <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
// We use those styles to show code examples, you should remove them in your application.
@import 'styles/examples';
</style>