<template>
  <CDropdown placement="bottom-end" variant="nav-item">
    <CDropdownToggle class="py-0 pe-0" :caret="false">
      <CAvatar :src="avatarUrl" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold mb-2 rounded-top"
      >
        Account
      </CDropdownHeader>
      <p>{{ userFirstName }} {{ userLastName }}</p>
      <p>{{ userDepartment }}</p>
      <CDropdownDivider/>
      <CDropdownItem @click="goToProfile"> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      
      <CDropdownItem @click="userLogOut"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>


<script setup lang="ts">
const avatarUrl = new URL('@/assets/images/avatars/8.jpg', import.meta.url).href;
const itemsCount = 42;
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import useUserStore from "@/stores/user";
import type { UserInfo } from "@/types/interfaces";
const store = useUserStore();
const router = useRouter();
const userFirstName = computed (() => store.userFirstName);
const userLastName = computed(() => store.userLastName);
const userDepartment = computed(() => store.userDepartment);
async function userLogOut() {
  try {
    await store.logout();
    router.push({ name: "login" });
  } catch (error) {
    console.log(error);
  }
}

function goToProfile() {
    router.push('/user/profile');
}
</script>