import { defineStore } from "pinia";
import axios from "axios";
import { backendHost } from '@/env';
import type { SiteInfoResponse } from '@/types/interfaces';

export default defineStore("general", {
    state: () => ({
        siteName: localStorage.getItem("siteName") || null,
        siteLogo: localStorage.getItem("siteLogo") || null,
        siteFavicon: localStorage.getItem("siteFavicon") || null,
        host: localStorage.getItem("host") || null,
        shopifyStoreName: localStorage.getItem("shopifyStoreName") || null
      }),
    actions: {
        async getSiteInfo(): Promise<SiteInfoResponse> {
            const res = await axios.get<SiteInfoResponse>(`${backendHost}/accounts/common/?action=get_site_name`);
            this.setSiteName(res.data.data.site_info.name);
            this.setSiteLogo(res.data.data.site_info.site_logo);
            this.setSiteFavico(res.data.data.site_info.site_favicon);
            this.setHost(res.data.data.site_info.host);
            this.setShopifyStoreName(res.data.data.site_info.shopify_store_name);
            return res.data;
        },
        setSiteName(name : string) {
            this.siteName = name;
            localStorage.setItem("siteName", name);
        },
        setSiteLogo(logo : string) {
            this.siteLogo = logo;
            localStorage.setItem("siteLogo", logo);
        },
        setSiteFavico(favico : string) {
            this.siteFavicon = favico;
            localStorage.setItem("siteFavicon", favico);
        },
        setHost(host : string) {
            this.host = host;
            localStorage.setItem("host", host);
        },
        setShopifyStoreName(shopifyStoreName: string) {
            this.shopifyStoreName = shopifyStoreName;
            localStorage.setItem("shopifyStoreName", shopifyStoreName);
        }

    },
    getters: {
    }
})