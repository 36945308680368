<template>
    <div class="wrapper min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <Overlay :showOverlay="showOverlay" ref="overlayElem" @update:showOverlay="showOverlay = $event" />
        <CRow class="justify-content-center">
          <CCol :xl="6" :lg="6" :md="6" :sm="8" :xs="12">
            <div class="card-header text-center">
                <CImage rounded thumbnail :src="logoSrc" width="200" height="200"/>
                <h1>{{ siteName }}</h1>
            </div>
            <CCardGroup>
              <CCard class="p-4">
                <CCardBody>
                  <CForm @submit.prevent="login" class="login-form">
                    <CRow class="text-center">
                        <h3>Login</h3>
                        <p class="text-body-secondary">Sign In to your account</p>
                    </CRow>
                    <CInputGroup class="mb-3">
                      <CInputGroupText>
                        <CIcon icon="cil-user" />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Username"
                        autocomplete="username"
                        v-model="username"
                        required
                      />
                    </CInputGroup>
                    <CInputGroup class="mb-4">
                      <CInputGroupText>
                        <CIcon icon="cil-lock-locked" />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autocomplete="current-password"
                        v-model="password"
                        required
                      />
                    </CInputGroup>
                    <CInputGroup class="mb-4">
                        <CFormCheck id="remember" label="Remember Me" v-model="rememberMe" />
                    </CInputGroup>
                    <div class="alert" v-if="loginShowAlert"
                            :class="loginAlertVariant">
                            <span v-if="isLoading" class="display: inline !important;"><i class="fas fa-spinner fa-spin"></i></span>
                            {{ loginAlertMsg }}
                    </div>
                    <div class="alert alert-danger" v-if="refreshTokenFailed">
                        Unable to refresh your session. Please log in again.
                    </div>
                    <CRow class="text-center">
                      <CCol :xs="12">
                        <CButton color="primary" class="px-4" type="submit"> 
                            Login </CButton>
                      </CCol>
                      <CCol :xs="12" class="text-right">
                        <CButton @click="forgotPassword()" color="link" class="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </template>  

<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import Overlay from "@/components/Overlay.vue";
import { ref, reactive, computed, onMounted, nextTick } from "vue";
import useUserStore from "@/stores/user";
import useGeneralStore from "@/stores/general";
const showOverlay = ref(false);
const overlayElem = ref<InstanceType<typeof Overlay> | null>(null);
const store = useUserStore();
const generalStore = useGeneralStore();
const router = useRouter();
const route = useRoute();
const username = ref<string | null>(null);
const password = ref("");
const rememberMe = ref<boolean | null>(null);
const siteName = computed(() => generalStore.siteName);
const logoSrc = computed(() => `${generalStore.host}${generalStore.siteLogo}`);
const loginSubmission = ref(false);
const loginShowAlert = ref(false);
const loginAlertVariant = ref("");
const loginAlertMsg = ref("Please wait, you are being logged in.");
const isLoading = ref(true);
const refreshTokenFailed = computed(() => 'refreshFailed' in route.query);

async function login() {
    loginShowAlert.value = true;
    loginAlertVariant.value = 'alert-info';
    loginAlertMsg.value = 'Logging in...';
    isLoading.value = true;
    showOverlay.value = true;
    await nextTick();
    setTimeout(async () => {
        
        try {
            let remember_me =  rememberMe.value ? true : false;

            if (username.value && password.value && remember_me !== null) {
                const tryLogin = await store.authenticate(username.value, password.value, remember_me);
                if (tryLogin.loginResponse === "success") {
                    setTimeout(() => {
                        router.push("/");
                    }, 1200)
                } else {
                    throw new Error(tryLogin.loginResponse);
                }
            } else {
                throw new Error('Credentials required.');
            }
        } catch (error : any) {
            loginShowAlert.value = true;
            loginAlertVariant.value = 'alert-danger';
            loginAlertMsg.value = error.toString().replace(/Error: /g, '');
            isLoading.value = false;
        }
        
    }, 800);
}

onMounted(async () => {
    let remember_me =  JSON.parse(localStorage.getItem("rememberMe") || 'null');

    if (remember_me) {
        rememberMe.value = true;
        if (localStorage.getItem("userName")) {
            username.value = localStorage.getItem("userName");
        }
    }
});

function forgotPassword() {
    router.push('/auth/forgotpassword');
}
</script>