<template>
    <div v-if="overlayVisible" class="overlay">
      <div class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, watch } from 'vue';
  
  // Props to control visibility
  const props = defineProps({
    showOverlay: {
      type: Boolean,
      default: false
    }
  });

  const emit = defineEmits(['update:showOverlay']);

  const overlayVisible = ref(props.showOverlay);

  watch(() => props.showOverlay, (newVal) => {
    overlayVisible.value = newVal;
    });

  const hideOverlayWithDelay = () => {
    setTimeout(() => {
        overlayVisible.value = false;
        emit('update:showOverlay', false);
    }, 800) // default delay
  }

  defineExpose({ hideOverlayWithDelay });
  </script>
  
  <style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* 20% opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top */
  }
  
  .fa-spinner {
    font-size: 50px;
  }
  </style>
  