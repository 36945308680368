<script setup>
import { ref, computed, onMounted } from "vue"
import { RouterLink } from 'vue-router'
import { AppSidebarNav } from '@/components/AppSidebarNav.js'
import { useSidebarStore } from '@/stores/sidebar.js'
import useGeneralStore from '@/stores/general'
import { toTitleCase, resolveImage, renderSiteNameAsInitials } from "@/helpers/genericHelpers";
const generalStore = useGeneralStore();

const logoSrc = ref(computed(() => `${generalStore.host}${generalStore.siteLogo}`));
const siteName = computed(() => generalStore.siteName);
const logo = ref(null);
const headerSiteName = ref(null);
const sidebar = useSidebarStore();
onMounted(async () => {
    logo.value = resolveImage(logoSrc.value);
    headerSiteName.value = renderSiteNameAsInitials(siteName.value);
});
</script>

<template>
  <CSidebar
    class="border-end"
    colorScheme="dark"
    position="fixed"
    :unfoldable="sidebar.unfoldable"
    :visible="sidebar.visible"
    @visible-change="(value) => sidebar.toggleVisible(value)"
  >
    <CSidebarHeader class="border-bottom">
      <RouterLink custom to="/" v-slot="{ href, navigate }">
        <CSidebarBrand v-bind="$attrs" as="a" :href="href" @click="navigate">
          <CImage custom-class-name="sidebar-brand-full" :src="logo" :height="32" />
        </CSidebarBrand>
          <div class="sidebar-brand-sitename"><h2>{{ headerSiteName }}</h2></div>
        
      </RouterLink>
      <CCloseButton class="d-lg-none" dark @click="sidebar.toggleVisible()" />
    </CSidebarHeader>
    <AppSidebarNav />
    <CSidebarFooter class="border-top d-none d-lg-flex">
      <CSidebarToggler @click="sidebar.toggleUnfoldable()" />
    </CSidebarFooter>
  </CSidebar>
</template>
