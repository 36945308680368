const env = import.meta.env.VITE_ENV;
export const setCookie = (
    name: string,
    value: string,
    days: number = 1
) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "; expires=" + date.toUTCString();
    
    const secure = env === 'production';
    const sameSite = env === 'production' ? 'None' : 'Lax';
    const httpOnly = false;  // Always true for security reasons, change if necessary

    const secureFlag = secure ? "; Secure" : "";
    const sameSiteFlag = `; SameSite=${sameSite}`;
    const httpOnlyFlag = httpOnly ? "; HttpOnly" : "";
    document.cookie = `${name}=${value || ""}${expires}; path=/${secureFlag}${sameSiteFlag}${httpOnlyFlag}`;
};

export const getCookie = (name: string): string | null => {
    const nameEQ = `${encodeURIComponent(name)}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
};

export const removeCookie = (name: string) => {
    const secureFlag = env === 'production' ? "; Secure" : "";
    const sameSiteFlag = env === 'production' ? "; SameSite=None" : "; SameSite=Lax";
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;${secureFlag}${sameSiteFlag};`;
};

export const set2faCookie = (name: string, value: string, days: number) => {
    let expires = "";
    if (days ? days : 1) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}