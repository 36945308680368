// axiosInstance.ts
import { computed } from "vue";
import axios from 'axios';
import useUserStore from "@/stores/user";
import { backendHost } from '@/env';
import { generateRandomHash } from '@/helpers/genericHelpers';
import router from '@/router';
import { getCookie } from '@/utils/cookie';


const axiosInstance = axios.create({
    baseURL: backendHost,
    params: {}, // Enable params option
});

axiosInstance.interceptors.request.use((config) => {
    const store = useUserStore();
    const accessToken = computed(() => store.accessToken);
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken.value}`;
    } else {
        console.log('trying to get from cookies');
        console.log(getCookie('accessToken'));
        if (getCookie('accessToken')) {
            config.headers['Authorization'] = `Bearer ${getCookie('accessToken')}`;
        }
    }

    if (!(config.data instanceof FormData) && !config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
    }

    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const store = useUserStore();
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem("refresh");
            try {
                const refreshRequest = await store.refreshAccessToken();

                if (refreshRequest.refreshResponse.result == "success") {
                    const retryAxiosInstance = axios.create();
                    const newConfig = {
                        ...originalRequest,
                        headers: {
                            ...originalRequest.headers,
                            'Authorization': `Bearer ${refreshRequest.refreshResponse.access}`
                        }
                    };

                    if (!newConfig.baseURL) {
                        newConfig.baseURL = axiosInstance.defaults.baseURL;
                    }
                    // Resend the request with the new configuration
                    return retryAxiosInstance(newConfig);
                } else {
                    store.logout();
                    throw new Error(refreshRequest.refreshResponse.result);
                }

            } catch (refreshError) {
                console.log(refreshError);
                store.logout();
                router.push('/auth/login');
            }
            const hash = generateRandomHash();
            router.push({ name: 'sessionExpired', params: { hash } });
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
