import { defineStore } from "pinia";
import axios from 'axios';
import axiosInstance from '@/utils/axiosInstance';
import type { RefreshResponse, GenericApiResponse, UserInfo } from '@/types/interfaces';
import { setCookie, getCookie, removeCookie } from '@/utils/cookie';

const backendHost = import.meta.env.VITE_BACKEND_HOST;

export default defineStore("user", {
    state: () => ({
        userLoggedIn: localStorage.getItem("userLoggedIn") || false,
        accessToken : (() => {
            if (getCookie('accessToken')) {
                return getCookie('accessToken');
            } else {
                return null;
            }
        })(),
        userName: localStorage.getItem("userName") || null,
        userFirstName: localStorage.getItem("userFirstName") || null,
        userLastName: localStorage.getItem("userLastName") || null,
        userEmail: localStorage.getItem("userEmail") || null,
        userDepartment: localStorage.getItem("userDepartment") || null,
        userRole: localStorage.getItem("userRole") || null,
        lastLogin: localStorage.getItem("lastLogin") || null,
        passwordChangeRequired: localStorage.getItem("passwordChangeRequired") || false,
        twoFactorSetupDone: (() => {
            const storedValue = localStorage.getItem("twoFactorSetupDone");
            if (storedValue === null) {
                return false;
            }
            try {
                return JSON.parse(storedValue);
            } catch (e) {
                return false;
            }
        })(),
        twoFactorAuthRequired: localStorage.getItem("twoFactorAuthRequired") || false,
        userPermsJson: localStorage.getItem("userPermsJson") || null,
        rememberMe: localStorage.getItem("rememberMe") || null,
    }),
    actions: {
        async authenticate(
            username: string,
            password: string,
            rememberMe: boolean
        ): Promise<{ loginResponse: string }> {
            const userCreds = {
                username: username,
                password: password,
                rememberMe: rememberMe
            };

            try {
                const res = await axios.post(`${backendHost}/api/token/`, userCreds);

                if (res.data.refresh && res.data.access) {
                    const userInfo: UserInfo = res.data.userInfo;

                    this.setUserInfo(userInfo);
                    this.setAccessToken(res.data.access);
                    setCookie('accessToken', res.data.access, 1);

                    // Set refreshToken in HttpOnly cookie (replace 'your-refresh-token' with actual token)
                    setCookie('refreshToken', res.data.refresh, 7);

                    // Manage rememberMe in localStorage
                    if (rememberMe) {
                        localStorage.setItem("userName", username);
                        localStorage.setItem("rememberMe", rememberMe.toString());
                    } else {
                        localStorage.removeItem("userName");
                        localStorage.removeItem("rememberMe");
                    }

                    this.userLoggedIn = true;
                    return {
                        loginResponse: "success"
                    };
                } else {
                    // Handle authentication failure
                    this.userLoggedIn = false;
                    return {
                        loginResponse: res.data.message || "Authentication failed"
                    };
                }
            } catch (error) {
                console.error("Error during authentication:", error);
                return {
                    loginResponse: "An error occurred during authentication"
                };
            }
        },
        setUserInfo(userInfo: UserInfo) {
            this.userLoggedIn = true;
            localStorage.setItem("userLoggedIn", "true");
            this.userFirstName = userInfo.first_name;
            localStorage.setItem("userFirstName", userInfo.first_name);
            this.userLastName = userInfo.last_name;
            localStorage.setItem("userLastName", userInfo.last_name);
            this.userEmail = userInfo.email;
            localStorage.setItem("userEmail", userInfo.email);
            this.lastLogin = userInfo.last_login;
            localStorage.setItem("lastLogin", userInfo.last_login);
            this.userDepartment = userInfo.get_group_name;
            localStorage.setItem("userDepartment", userInfo.get_group_name);
            this.passwordChangeRequired = userInfo.password_change_required;
            localStorage.setItem("passwordChangeRequired", JSON.stringify(userInfo.password_change_required));
            this.twoFactorSetupDone = userInfo.two_factor_auth_setup_done;
            localStorage.setItem("twoFactorSetupDone", JSON.stringify(userInfo.two_factor_auth_setup_done));
            this.twoFactorAuthRequired = userInfo.two_factor_auth_required;
            localStorage.setItem("twoFactorAuthRequired", JSON.stringify(userInfo.two_factor_auth_required));
            this.userPermsJson = userInfo.user_permissions_json;
            localStorage.setItem("userPermsJson", JSON.stringify(userInfo.user_permissions_json));

        },
        unsetUserInfo() {
            this.userLoggedIn = false;
            localStorage.setItem("userLoggedIn", "false");
            this.userFirstName = null;
            localStorage.removeItem("userFirstName");
            this.userLastName = null;
            localStorage.removeItem("userLastName");
            this.userEmail = null;
            localStorage.removeItem("userEmail");
            this.lastLogin = null;
            localStorage.removeItem("lastLogin");
            this.userDepartment = null;
            localStorage.removeItem("userDepartment");
            this.userRole = null;
            localStorage.removeItem("userRole");
            this.passwordChangeRequired = false;
            localStorage.removeItem("passwordChangeRequired");
            this.twoFactorAuthRequired = false;
            localStorage.removeItem("twoFactorAuthRequired");
            this.twoFactorSetupDone = false;
            localStorage.removeItem("twoFactorSetupDone");
            this.userPermsJson = null;
            localStorage.removeItem("userPermsJson");
        },
        setAccessToken(token : string) {
            this.accessToken = token;
        },
        async logout(): Promise<GenericApiResponse> {
            try {
                const response = await axiosInstance.get<GenericApiResponse>(`${backendHost}/admin/useraccounts/api/v1/?action=logout`);
                if (response.data.message == 'Successfully logged out') {
                    this.unsetUserInfo();
                    removeCookie('refreshToken');
                    let is2faVerified = getCookie('_2facookie');
                    removeCookie('_2facookie');
                    this.userLoggedIn = false;
                    this.accessToken = null;
                }
                
                return response.data;
            } catch (error) {
                console.error('Error fetching user info:', error);
                throw error;
            }
        },
        async verifyToken(): Promise<{ verifyResponse: String }> {
            let verifyResult = "token is invalid";
            try {
                const res = await axiosInstance.get(`${backendHost}/api/token/verify`);
                verifyResult = res.data.result;
                if (res.data.result == "token is valid") {
                    //
                } else {
                    await this.refreshAccessToken();
                }
            } catch (error) {
                await this.refreshAccessToken();
            }
            return {
                verifyResponse: verifyResult,
            };
        },
        async refreshAccessToken(): Promise<{
            refreshResponse: RefreshResponse;
        }> {
            let result = {
                result: "error",
                access: ""
            };

            let currentRefreshToken = getCookie('refreshToken')
                ? getCookie('refreshToken')
                : null;
            
            if (currentRefreshToken) {
                const res = await axios.post(`${backendHost}/api/token/refresh/`, {
                    refresh: currentRefreshToken,
                });
                if (res.data.access) {
                    this.setAccessToken(res.data.access);
                    this.userLoggedIn = true;
                    result['result'] = "success";
                    result['access'] = res.data.access;
                } else {
                    this.userLoggedIn = false;
                    result = res.data.detail;
                }
            } else {
                console.log('we dont have a refresh token');
                result = {
                    result: 'refresh failed',
                    access: "null"
                }
            }
            return {
                refreshResponse: result,
            };
        },
        async getUserInfo(): Promise<GenericApiResponse> {
            try {
                const response = await axiosInstance.get<GenericApiResponse>(`${backendHost}/admin/useraccounts/api/v1/?action=get_user_info`);
                const userInfo: UserInfo = response.data.data;
                this.setUserInfo(userInfo);
                return response.data;
            } catch (error) {
                console.error('Error fetching user info:', error);
                throw error;
            }
        }
    },
    getters: {
        getAccessToken: (state) => state.accessToken,
    },
});