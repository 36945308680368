import { createApp, watch } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGooglePlus } from "@fortawesome/free-brands-svg-icons";

import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';
import DocsExample from '@/components/DocsExample';
import '@/assets/css/main.css';
library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);

dom.watch();
const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.use(CoreuiVue);

import moment from "moment";
app.config.globalProperties.$moment = moment;

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
app.component("EasyDataTable", Vue3EasyDataTable);

import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
app.component("v-select", VueSelect);

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
app.component("Datepicker", Datepicker);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; // Import SweetAlert2 styles
app.use(VueSweetalert2);

window.Swal =  app.config.globalProperties.$swal;

app.provide('icons', icons);
app.component('CIcon', CIcon);
app.component('DocsExample', DocsExample);
app.mount('#app');

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

watch(
    pinia.state,
    (state) => {
    },
    { deep: true }
);